// Import custom svgs
import MailSVG from "./MailSVG";
import YoutubeSVG from "./YoutubeSVG";
import FacebookSVG from "./FacebookSVG";
import InstagramSVG from "./InstagramSVG";
// Export svgs
export const socialIcons = {
  MailSVG,
  YoutubeSVG,
  FacebookSVG,
  InstagramSVG,
};
