// Export svg styles
export const SVGStyles = `

svg{
    transition: stroke 0.1s ease-in-out;
    &:hover{
         @media (min-width: 1280px) {
        stroke: rgba(255, 255, 255, 0.7);
      }
    }
}
`;
